import { Box, Paper, styled, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ContactForm } from '../components/forms/ContactForm';
import { useMetadata } from '../context/metadata';
import Layout from '../layout/Layout';
import { theme } from '../layout/styles';

const tableStyles: SxProps = {
  border: '1px solid',
  marginTop: '3rem',
  borderColor: theme.palette.grey[400],
  borderCollapse: 'collapse',
  '& td': {
    padding: '0.5rem',
  },
  '& tr': {
    border: '1px solid',
    borderColor: 'inherit',
  },
  '& tr > td:nth-of-type(1)': {
    width: '65%',
  },
};
const containerStyles: SxProps = {
  display: 'grid',
  padding: '2rem 0',
  gridTemplateColumns: '1fr',
  gap: '1rem',
  [theme.breakpoints.up('md')]: {
    gridTemplateColumns: 'repeat(2, calc(50% - 2rem))',
    gap: '4rem',
  },
  [theme.breakpoints.up('lg')]: {
    gridTemplateColumns: '2fr 1fr',
  },
  '& > div:nth-of-type(2)': {
    width: '100%',
    margin: '0 auto',
  },
};

const StyledList = styled((props: any) => <Box component="ul" {...props} />)({
  listStyle: 'none',
  '& > li:not(:last-child)': {
    paddingBottom: '0.2rem',
  },
});

export default ({ location }: { location: Location }) => {
  const { email, phone, address, fax, billing, workingSchedule, siteTitle, timezoneOffset } =
    useMetadata();
  const { t } = useTranslation('common');

  const contactInfo = [
    {
      label: 'Email',
      value: (
        <a href={`mailto:${email}`} target="_blank" rel="noreferrer">
          {email}
        </a>
      ),
    },
    {
      label: 'Tel',
      value: (
        <a href={`tel:${phone}`} target="_blank" rel="noreferrer">
          {phone}
        </a>
      ),
    },
    {
      label: 'Fax',
      value: fax,
    },
  ];

  const paymentInfo = [
    {
      label: 'IBAN',
      value: billing.iban,
    },
    {
      label: 'SWIFT',
      value: billing.swift,
    },
  ];

  return (
    <Layout location={location}>
      <Box sx={containerStyles}>
        <div>
          <strong>
            <Typography variant="h4">{t('glossary:contact')}</Typography>
          </strong>
          <Paper
            sx={{
              marginTop: '2rem',
              padding: '2.5rem',
              '& > p': {
                color: theme.palette.grey[600],
                marginBottom: '1rem',
              },
            }}
          >
            <Typography>{t('contact-form-title')}</Typography>
            <ContactForm />
          </Paper>
        </div>
        <Box>
          <Typography variant="h4" sx={{ fontSize: '1rem', lineHeight: 2.5 }}>
            {siteTitle}
          </Typography>
          <Box sx={{ color: theme.palette.grey[600], fontWeight: 400 }}>
            <Box sx={{ display: 'grid', gap: '0.75rem' }}>
              <StyledList>
                <li>{address.street}</li>
                <li>{address.city}</li>
                <li>{address.country}</li>
              </StyledList>
              <StyledList>
                {contactInfo.map((item) => (
                  <li key={item.label}>
                    <strong>{item.label}:</strong> {item.value}
                  </li>
                ))}
              </StyledList>
              <StyledList>
                <li>
                  <strong>VAT:</strong> {billing.vat}
                </li>
              </StyledList>
              <StyledList>
                {paymentInfo.map((item) => (
                  <li key={item.label}>
                    <strong>{item.label}:</strong> {item.value}
                  </li>
                ))}
              </StyledList>
            </Box>
            <Box component="table" sx={tableStyles}>
              <tbody>
                {workingSchedule.map((schedule) => (
                  <tr key={`key-schedule-${schedule.day}`}>
                    <td>{schedule.day}</td>
                    <td>{schedule.time}</td>
                  </tr>
                ))}
              </tbody>
            </Box>
            {t('timezone', { offset: timezoneOffset })}
          </Box>
        </Box>
      </Box>
    </Layout>
  );
};
